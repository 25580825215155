// .cache/gatsby-mdx/wrapper-components/{wrapper-filepath-hash}-{scope-hash}.js
import React from 'react';
import { MDXScopeProvider } from 'gatsby-mdx/context';

import __mdxScope_0 from '/Users/greghurrell/code/alloy-editor/website/.cache/gatsby-mdx/remote-mdx-dir/3010b3badc54a9dfa4a4c80e419a41b2.js';

import OriginalWrapper from '/Users/greghurrell/code/alloy-editor/website/src/templates/updates.js';

import { graphql } from 'gatsby';

// pageQuery, etc get hoisted to here
export const pageQuery = graphql`
	query($slug: String!) {
		mdx(fields: {slug: {eq: $slug}}) {
			excerpt
			timeToRead
			frontmatter {
				title
				updates {
					version
					major
					features {
						icon
						title
						description
						url
					}
				}
			}
		}
	}
`;;

export default ({children, ...props}) => <MDXScopeProvider __mdxScope={{...__mdxScope_0}}>
  <OriginalWrapper
    {...props}
  >
    {children}
  </OriginalWrapper>
</MDXScopeProvider>